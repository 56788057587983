import sha256 from 'crypto-js/sha256'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { useBoundStore } from '../store/store'
import type { ApiResponse } from '../types/api'
import { api } from '../utils/api-client'

interface DepositEvent {
  account: string
  amount: string
  bank_account: string
  bank_account_name: string
  billno: string
  cardID: string
  checkPerson: string
  depStatus: string
  endTime: string
  fee: string
  for_bankverify: string
  id: string
  payType: string
  platInfo: string
  requestTime: string
  saveName: string
  saveTime: string
  userType: string
  is_first_deposit: number
}

const useDepositEvents = () => {
  const username = useBoundStore(state => state.user.username)
  const email = useBoundStore(state => state.user.email)
  const phone = useBoundStore(state => state.user.phone)
  const userId = useBoundStore(state => state.user.userID)
  const birthday = useBoundStore(state => state.user.birthday)
  const firstName = useBoundStore(state => state.user.first_name)
  const lastName = useBoundStore(state => state.user.last_name)
  const userIP = useBoundStore(state => state.settings.userIP)

  useEffect(() => {
    if (!username) return
    const getDepositEvents = async () => {
      try {
        const res = await api.post<ApiResponse<DepositEvent[]>>(
          '/conduct.php',
          {
            type: 'get_new_successful_deposits',
          },
        )
        const { status, info } = res.data
        if (status === 1 && info.length >= 1) {
          info.forEach(depositEvent => {
            if (+depositEvent.is_first_deposit) {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'FirstTimeDeposit',
                  email,
                  transaction_id: depositEvent.billno,
                  value: depositEvent.amount,
                  currency: 'MXN',
                  items: [
                    {
                      item_id: depositEvent.platInfo,
                      item_name: depositEvent.platInfo,
                      price: depositEvent.amount,
                      quantity: '1',
                      currency: 'MXN',
                    },
                  ],
                },
              })
            }

            TagManager.dataLayer({
              dataLayer: {
                event: 'purchase',
                transaction_id: depositEvent.billno,
                value: depositEvent.amount,
                currency: 'MXN',
                items: [
                  {
                    item_id: depositEvent.platInfo,
                    item_name: depositEvent.platInfo,
                    price: depositEvent.amount,
                    quantity: '1',
                    currency: 'MXN',
                  },
                ],
              },
            })

            // FB
            api.fbGraph({
              data: [
                {
                  action_source: 'website',
                  event_id: 12344,
                  event_name: 'Purchase',
                  event_time: dayjs().unix(),
                  user_data: {
                    em: [email ? sha256(email).toString() : null],
                    ph: [phone ? sha256(phone).toString() : null],
                    client_user_agent: navigator.userAgent,
                    client_ip_address: userIP,
                    external_id: [userId ? sha256(userId).toString() : null],
                    db: [birthday ? sha256(birthday).toString() : null],
                    fn: [firstName ? sha256(firstName).toString() : null],
                    ln: [lastName ? sha256(lastName).toString() : null],
                  },
                  custom_data: {
                    transaction_id: depositEvent.billno,
                    value: depositEvent.amount,
                    currency: 'MXN',
                    platform: depositEvent.platInfo,
                  },
                },
              ],
            })
            api.fbGraph2({
              data: [
                {
                  action_source: 'website',
                  event_id: 12344,
                  event_name: 'Purchase',
                  event_time: dayjs().unix(),
                  user_data: {
                    em: [email ? sha256(email).toString() : null],
                    ph: [phone ? sha256(phone).toString() : null],
                    client_user_agent: navigator.userAgent,
                    client_ip_address: userIP,
                    external_id: [userId ? sha256(userId).toString() : null],
                    db: [birthday ? sha256(birthday).toString() : null],
                    fn: [firstName ? sha256(firstName).toString() : null],
                    ln: [lastName ? sha256(lastName).toString() : null],
                  },
                  custom_data: {
                    transaction_id: depositEvent.billno,
                    value: depositEvent.amount,
                    currency: 'MXN',
                    platform: depositEvent.platInfo,
                  },
                },
              ],
            })
            
            // Twitter
            twq('event', 'tw-olsug-oltwu', {
              value: depositEvent.amount,
              currency: 'MXN',
              contents: [depositEvent],
              email_address: email,
              phone_number: `+52${phone}`,
            })
          })

          await api.post('/conduct.php', {
            type: 'clear_new_successful_deposits',
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    getDepositEvents()
  }, [username])
}

export default useDepositEvents
